<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';

type CloseTrigger = 'click' | 'hover';
const props = withDefaults(
  defineProps<{
    toggleTrigger?: CloseTrigger;
  }>(),
  {
    toggleTrigger: 'click',
  }
);
const emit = defineEmits<{
  (e: 'close:floating-panel'): void;
}>();

// スクロールしたときに、通知一覧を消す
const { x, y } = useWindowScroll();
watch([x, y], () => emit('close:floating-panel'));

const showBackdrop = computed(() => {
  switch (props.toggleTrigger) {
    case 'click':
      return true;
    case 'hover':
      return false;
    default:
      return false;
  }
});
</script>

<template>
  <div :class="$style.wrapper">
    <div
      v-if="showBackdrop"
      :class="$style.wrapper__backdrop"
      @click="emit('close:floating-panel')"
    />
    <div ref="rootElement" :class="[$style.wrapper__panel, $style.panel]">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  &__backdrop {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  &__panel {
    position: relative;
    z-index: 1;
  }
}
.panel {
  border: 1px solid $color-border-low-emphasis;
  border-radius: $border-radius-rounder;
  box-shadow: $shadow-4;
  background-color: $color-surface-primary;
  overflow: hidden;
  z-index: 1;
}
</style>
